/**
 * helper function to get a deep prop from an object, if that prop exists.
 * @param object - the object to extract the prop from
 * @param prop - such as a.b.c
 * @returns value of object[prop], or undefined.
 */
export function get<T>(object: T | T[], prop?: string | number): any {
  if (prop === undefined) return object;
  if (typeof object !== 'object') return undefined; // just in case.
  if (object === null) return undefined; // also just in case, since null evaluates to object in javascript.
  const props = `${prop}`.split('.');
  if (props.length === 1) return (object as any)[props[0]];
  return get((object as any)[props.shift() as string], props.join('.'));
}
